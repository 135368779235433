import React from 'react';

const IconLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsOsb="http://www.openswatchbook.org/uri/2009/osb"
    width="600"
    height="600"
    viewBox="0 0 158.75 158.75">
    <defs>
      <linearGradient id="c" gradientTransform="translate(116.17 -.783) scale(.85076)">
        <stop offset="0" stopColor="#e6f1ff"></stop>
      </linearGradient>
      <linearGradient id="b" gradientTransform="matrix(.0559 0 0 .07255 -147.56 31.854)">
        <stop offset="0" stopColor="#03a87c"></stop>
      </linearGradient>
      <linearGradient id="a" gradientTransform="matrix(.0014 0 0 .0019 -259.23 .836)">
        <stop offset="0" stopColor="#252c34"></stop>
      </linearGradient>
    </defs>
    <g transform="translate(0 -138.25) translate(185.21 145.14)">
      <path
        fill="url(#a)"
        d="M-164.04 -6.893H-47.61999999999999V151.857H-164.04z"
        opacity="0.96"></path>
      <path
        fill="url(#b)"
        d="M-185.21 32.795H-26.460000000000008V112.17H-185.21z"
        opacity="0.96"></path>
      <g fill="url(#c)" ariaLabel="WA" transform="translate(-185.21 -145.14)">
        <path d="M56.591 200.44h-6.76l-7.886 41.027-8.261-57.175H27.3l10.327 66.657H45.7l7.699-40.933 8.825 40.933h7.51l9.576-66.657h-6.008L65.604 242.5zM125.54 250.95h5.915v-23.189l-15.772-43.468h-10.515L89.49 228.23v22.72h5.914v-19.809h30.136zm-15.491-61.212h1.033l13.144 35.676h-27.32z"></path>
      </g>
    </g>
  </svg>
);

export default IconLogo;
